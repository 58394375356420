import Home from './PortfolioContainer/HomePage/Home';
import './App.css';


const App = () => {
  return (
    <div className="App">
     <Home />
    </div>
  ); 
}

export default App;
